import React from 'react';
import {Loader} from "rsuite"

export default function LoaderComponent() {
    return (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <Loader />
        </div>
    )
}
