import React,{Suspense} from "react";
import "swagger-ui-react/swagger-ui.css"
import 'rsuite/dist/styles/rsuite-default.css';
import "./styles/bootstrap/bootstrap.min.css";
import "./styles/main.css";
import "./styles/mediaQuery.css";
import "./styles/table.css"
import { BrowserRouter as Router, Route,Switch } from "react-router-dom";
import Modal from "./components/Modal";
const AppScreen = React.lazy(()=>import("./screens/AppScreen"));
const ResetPassword =  React.lazy(()=>import("./screens/ResetPassword"));
const LoginComponent = React.lazy(()=>import("./components/Login/LoginComponent"));
const ForgetPassword = React.lazy(()=>import("./screens/ForgetPassword"));
const Plugin  = React.lazy(()=>import("./pages/plugin"));
const LandingPage  = React.lazy(()=>import("./pages/landing_page"));
const APIDOCS  = React.lazy(()=>import("./pages/apidocs"));
const BillDOCS  = React.lazy(()=>import("./pages/bills"));
function App() {
    if (localStorage.getItem("language") == null) {
      localStorage.setItem("language", navigator.language.split("-")[0]);
    }
  return (
      <Router>
        <Suspense fallback={
          <Modal bodyText="" icon={""} action={()=>{}} loading={true} fixedModal={true} />
        }>
      <div className="App">
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/plugin" component={Plugin} />
        <Route exact path="/docs" component={APIDOCS} />
        <Route exact path="/docs/bills" component={BillDOCS} />
        <Route exact path="/login" component={LoginComponent} />
        <Route component={ForgetPassword} path="/forget" />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route component={AppScreen} path="/dashboard"  />
      </Switch>

      </div>
        </Suspense>
    </Router>
  );
}

export default App;
