import React from "react";
import Loader from "./Loader";
function Modal({
  control,
  bodyText,
  icon,
  loading,
  action,
  btnText,
  transparentBackground,
  fixedModal,
  backdrop
}) {
  const config = { background: transparentBackground ? "transparent" : "", boxShadow: transparentBackground ? "none": "" };
  return (
    <div className={`modal-container ${backdrop && "backdrop"}`} style={{position: fixedModal ? "fixed": "relative"}}>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="my-modal" style={config}>
          <div className="modal-main d-flex justify-content-center" >
            <div className="modal-cont">
              <img src={icon} />
              <p>{bodyText}</p>
            </div>
            <div className="modal-con">
              <button
                id="close"
                onClick={() => {
                  action(control);
                }}
              >
                {btnText || "Go"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Modal;
